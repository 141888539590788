import classNames from 'classnames';
import React from 'react';

const VideoSeekBar: React.FC<{
  progress: number;
  onChangeProgress: React.ChangeEventHandler<HTMLInputElement>;
  onMouseUp?: () => void;
  className?: string;
}> = ({ progress, onChangeProgress, className, onMouseUp }) => {
  return (
    <input
      id="seek-bar"
      type="range"
      value={progress}
      min={0}
      max={100}
      step={0.25}
      className={classNames('seek-bar w-full', className)}
      onClick={(event) => event.stopPropagation()}
      onChange={onChangeProgress}
      onMouseUp={onMouseUp}
      style={
        {
          '--seek-bar-progress': progress,
        } as React.CSSProperties
      }
    />
  );
};

export default VideoSeekBar;
