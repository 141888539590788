import classNames from 'classnames';
import React, { useMemo, useRef, useState } from 'react';
import useFullscreenScrollReset from 'src/components/Video/hooks/useFullscreenScrollReset';
import { isBrowser } from 'src/utils';

import { Typography } from '@remarkable/ark-web';
import { VideoInteraction, VideoInteractionProperties } from 'ampli-types';
import { debounce } from 'lodash';
import { PlayCircle } from 'phosphor-react';
import { BP } from 'src/helpers/tailwindBreakpointsHelpers';
import { useActiveBreakpoint } from 'src/hooks/useActiveBreakpoint';
import { tracker } from 'src/services/tracker';
import { isMobile } from 'src/utils/isMobile';
import type { Props as VideoOverlayProps } from './DefaultVideoControlsOverlay';
import { DefaultVideoControlsOverlay } from './DefaultVideoControlsOverlay';

const exitFullscreen = async (): Promise<boolean> => {
  const fullscreenElement = document.fullscreenElement || document['webkitFullscreenElement'];

  if (!fullscreenElement) {
    return false;
  }

  if (document.exitFullscreen) {
    try {
      await document.exitFullscreen();
      return true;
    } catch (error) {
      console.log('Failed to exit fullscreen', error);
    }
  } else if (document['webkitExitFullscreen']) {
    document['webkitExitFullscreen']();
    return true;
  }

  return false;
};

const VideoOverlayWrapper: React.FC<
  VideoOverlayProps & { OverlayComponent?: React.ComponentType<VideoOverlayProps> }
> = ({ OverlayComponent, ...videoOverlayProps }) => {
  const Overlay = OverlayComponent ? OverlayComponent : DefaultVideoControlsOverlay;
  return <div className="absolute z-10 w-full h-full">{<Overlay {...videoOverlayProps} />}</div>;
};

interface VideoTrackingOptions {
  title: string;
  /**
   * meant to be used with ComponentLocations.x.y from "services/tracking/eventTypes.ts"
   */
  componentLocation: string;
}
const publishDebouncedVideoEvent = debounce(
  (
    title: string | undefined,
    location: string | undefined,
    action: VideoInteractionProperties['action'],
    source: string,
    currentTime: number
  ) => {
    if (!title || !location) {
      return;
    }
    try {
      const data: VideoInteractionProperties = {
        component_location: location,
        title,
        action,
        source,
        view_time: currentTime,
      };
      tracker.trackEvent(new VideoInteraction(data));
    } catch {
      console.error('Could not track video event');
    }
  },
  150
);
export const PlayButtonTextOverlay =
  (text?: string): React.FC<VideoOverlayProps & { OverlayComponent?: React.ComponentType<VideoOverlayProps> }> =>
  ({ OverlayComponent, onVideoSeekerMouseUp, ...videoOverlayProps }) => {
    const playText = text ?? 'Play video';
    return (
      <div
        onClick={videoOverlayProps.togglePlaying}
        data-theme="on-light"
        className={classNames(
          'relative z-10 flex flex-row items-center justify-center w-full h-full',
          {
            'cursor-pointer': videoOverlayProps.isHovering,
          },
          {
            'cursor-none': !videoOverlayProps.isHovering && videoOverlayProps.isPlaying,
          }
        )}
      >
        <button
          className={`${
            videoOverlayProps.isPlaying ? 'hidden' : 'block'
          } px-16 gap-8 absolute grid grid-cols-[24px_1fr] items-center border h-40 max-w-[268px] rounded-full bg-grayscale-gray-50 bg-opacity-[85%] border-transparent	 `}
        >
          <PlayCircle size={24} weight="fill" color="black" />
          <Typography variant="interface-md-demi">{playText}</Typography>
        </button>
        <div
          className={`${
            videoOverlayProps.isPlaying ? 'block' : 'hidden'
          } absolute flex bottom-48 h-24 w-full px-24 sm:px-0`}
        >
          <DefaultVideoControlsOverlay onVideoSeekerMouseUp={onVideoSeekerMouseUp} {...videoOverlayProps} />
        </div>
      </div>
    );
  };

type VideoPlayerProps = {
  url: string;
  width: string;
  rounded?: boolean;
  className?: string;
  videoClassName?: string;
  disableDefaultOverlay?: boolean;
  trackingOptions?: VideoTrackingOptions;
  Overlay?: React.ComponentType<VideoOverlayProps>;
} & Pick<React.VideoHTMLAttributes<HTMLVideoElement>, 'loop' | 'autoPlay' | 'playsInline' | 'poster' | 'onError'>;

const VideoPlayer: React.FC<VideoPlayerProps> = ({
  url,
  width,
  rounded = false,
  className,
  videoClassName,
  disableDefaultOverlay = false,
  Overlay,
  loop = true,
  autoPlay = false,
  playsInline = true,
  poster,
  onError,
  trackingOptions,
}) => {
  const { title, componentLocation } = trackingOptions ?? {};

  const [isHovering, setIsHovering] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [isMuted, setIsMuted] = useState(autoPlay); // If autoplay, disable mute by default
  const [currentTime, setCurrentTime] = useState(0); // In seconds
  const isIphone = /iPhone|iPod/.test(isBrowser() ? navigator.userAgent : ''); // Ignore iPad, since it supports full screen
  const currentBp: BP | null = useActiveBreakpoint();

  const trackVideoEvent = (action: VideoInteractionProperties['action']) =>
    publishDebouncedVideoEvent(title, componentLocation, action, url, currentTime);

  const videoRef = useRef<HTMLVideoElement>(null);
  const containerRef = useRef<
    HTMLDivElement & {
      webkitRequestFullscreen(): Promise<void> | undefined;
      webkitEnterFullscreen(): Promise<void> | undefined;
      mozRequestFullScreen(): Promise<void> | undefined;
    }
  >(null);

  useFullscreenScrollReset();

  const toggleVideo = async (event) => {
    event.stopPropagation();

    try {
      if (!isPlaying) {
        setIsPlaying(true);
        await videoRef?.current?.play();
        trackVideoEvent('play');
      } else {
        if (isMobile(currentBp) && !isHovering) {
          setIsHovering(true);
        } else {
          videoRef?.current?.pause();
          setIsPlaying(false);
          trackVideoEvent('stop');
        }
      }
    } catch (error) {
      setIsPlaying(false);
      console.warn(error);
    }
  };

  const toggleMute = (event) => {
    event.stopPropagation();
    if (isMuted) {
      trackVideoEvent('unmute');
    } else {
      trackVideoEvent('mute');
    }
    setIsMuted(!isMuted);
  };

  const toggleFullscreen = async (event) => {
    event.stopPropagation();

    if (await exitFullscreen()) {
      trackVideoEvent('fullscreen_close');
      setIsFullscreen(false);
      return;
    } else {
      trackVideoEvent('fullscreen_close');
    }

    const currentRef = containerRef.current;

    setIsFullscreen(true);
    if (currentRef?.requestFullscreen) {
      currentRef?.requestFullscreen();
      trackVideoEvent('fullscreen_open');
    } else if (currentRef?.webkitRequestFullscreen) {
      currentRef?.webkitRequestFullscreen();
      trackVideoEvent('fullscreen_open');
    } else if (currentRef?.webkitEnterFullscreen) {
      currentRef.webkitEnterFullscreen();
      trackVideoEvent('fullscreen_open');
    } else if (currentRef?.mozRequestFullScreen) {
      currentRef.mozRequestFullScreen();
      trackVideoEvent('fullscreen_open');
    } else {
      setIsFullscreen(false);
      console.warn('Fullscreen not supported');
      trackVideoEvent('fullscreen_close');
    }
  };

  const handleTimeUpdate = () => {
    if (videoRef.current) {
      // Update every 250ms
      if (videoRef.current.currentTime > currentTime + 0.25 || currentTime > videoRef.current.currentTime) {
        setCurrentTime(videoRef.current.currentTime);
      }
    }
  };

  const handleChangeProgress = (event) => {
    event.stopPropagation();

    const progress = Number(event.target.value);

    if (videoRef.current) {
      const progressInSeconds = (videoRef.current.duration / 100) * progress;

      if (isNaN(progressInSeconds)) {
        return;
      }

      videoRef.current.currentTime = progressInSeconds;
      setCurrentTime(progressInSeconds);
    }
  };

  const duration = videoRef.current?.duration ?? 1;
  const progress = (currentTime / duration) * 100;

  const hasCustomOverlay = !!Overlay;
  const showNativeControls = isIphone && !hasCustomOverlay;
  const showOverlay = !showNativeControls && !disableDefaultOverlay;

  const hideOverlayTimeout = () => {
    setIsHovering(false);
  };

  //Make sure we only have one instance of the hideOverlay function at any time
  const debouncedHideOverlay = useMemo(() => debounce(hideOverlayTimeout, 3000), []);

  return (
    <div
      ref={containerRef}
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
      onMouseMove={() => {
        setIsHovering(true);
        debouncedHideOverlay();
      }}
      className={classNames(
        'flex h-auto w-full justify-center items-center relative mx-auto group/video bg-transparent',
        className,
        {
          'rounded overflow-hidden': rounded,
        }
      )}
      style={{ maxWidth: width }}
    >
      {showOverlay && (
        <VideoOverlayWrapper
          OverlayComponent={Overlay}
          isHovering={isHovering}
          isPlaying={isPlaying}
          isMuted={isMuted}
          progress={isNaN(progress) ? 0.1 : progress}
          togglePlaying={toggleVideo}
          toggleMute={toggleMute}
          toggleFullscreen={toggleFullscreen}
          onChangeProgress={handleChangeProgress}
          onVideoSeekerMouseUp={debounce(() => {
            trackVideoEvent('search');
          }, 250)}
        />
      )}

      <video
        id="video"
        ref={videoRef}
        onClick={!showNativeControls ? toggleVideo : undefined}
        className={classNames('w-full max-w-full', !isFullscreen ? videoClassName : '')}
        preload={poster ? 'none' : 'metadata'}
        controls={showNativeControls}
        onPlay={() => setIsPlaying(true)}
        onPause={() => setIsPlaying(false)}
        onTimeUpdate={handleTimeUpdate}
        onError={onError}
        poster={poster}
        loop={loop}
        playsInline={playsInline}
        autoPlay={autoPlay}
        muted={isMuted} // Has to be set after autoPlay
      >
        <source src={`${url}#t=0.001`} />
      </video>
    </div>
  );
};

export default VideoPlayer;
