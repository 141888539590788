import classNames from 'classnames';
import { ArrowsOutSimple, Pause, Play, SpeakerSimpleHigh, SpeakerSlash } from 'phosphor-react';
import React from 'react';

export const PlayButton = ({
  isPlaying,
  onClick,
  className,
}: {
  isPlaying: boolean;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
  className?: string;
}) => {
  return (
    <button
      onClick={onClick}
      aria-label={isPlaying ? 'Pause video' : 'Play video'}
      data-cy={`is-playing-${isPlaying}`}
      className={classNames(
        'flex p-[15px] rounded-full transition-colors bg-dark/[.65] [&:not(:focus-visible)]:hover:bg-dark/[.90] text-light ark-focus-visible',
        className
      )}
    >
      {!isPlaying ? <Play size={18} weight="fill" /> : <Pause size={18} weight="fill" />}
    </button>
  );
};

export const FullscreenButton = ({
  onClick,
  className,
}: {
  onClick: React.MouseEventHandler<HTMLButtonElement>;
  className?: string;
}) => {
  return (
    <button
      onClick={onClick}
      aria-label="Fullscreen"
      data-cy="fullscreen-button"
      className={classNames(
        'flex p-8 rounded-full transition-colors bg-dark/[.65] [&:not(:focus-visible)]:hover:bg-dark/[.90] text-light ark-focus-visible',
        className
      )}
    >
      <ArrowsOutSimple size={16} weight="fill" />
    </button>
  );
};

export const MuteButton = ({
  isMuted,
  onClick,
  className,
}: {
  isMuted: boolean;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
  className?: string;
}) => {
  return (
    <button
      onClick={onClick}
      aria-label={isMuted ? 'Unmute' : 'Mute'}
      data-cy={isMuted ? 'unmute-button' : 'mute-button'}
      className={classNames(
        'flex p-8 rounded-full transition-colors bg-dark/[.65] [&:not(:focus-visible)]:hover:bg-dark/[.90] text-light ark-focus-visible',
        className
      )}
    >
      {isMuted ? <SpeakerSlash size={16} weight="fill" /> : <SpeakerSimpleHigh size={16} weight="fill" />}
    </button>
  );
};
