import classNames from 'classnames';
import { debounce } from 'lodash';
import { Play } from 'phosphor-react';
import React, { useEffect, useMemo, useState } from 'react';
import { FullscreenButton, MuteButton, PlayButton } from 'src/components/Video/VideoButtons';
import VideoSeekBar from 'src/components/Video/VideoSeekBar';
import { isBrowser } from 'src/utils';

export type Props = {
  isHovering: boolean;
  isPlaying: boolean;
  isMuted: boolean;
  progress: number;
  togglePlaying: React.MouseEventHandler<HTMLElement>;
  toggleMute: React.MouseEventHandler<HTMLElement>;
  toggleFullscreen: React.MouseEventHandler<HTMLElement>;
  onChangeProgress: React.ChangeEventHandler<HTMLInputElement>;
  onVideoSeekerMouseUp?: () => void;
};

const isFullscreenSupported = () =>
  isBrowser() &&
  !!(
    document.fullscreenEnabled ||
    document['webkitFullscreenEnabled'] ||
    document['webkitSupportsFullscreen'] ||
    document['mozFullscreenEnabled']
  );

export const DefaultVideoControlsOverlay: React.FC<Props> = ({
  isHovering,
  isPlaying,
  isMuted,
  progress,
  togglePlaying,
  toggleMute,
  toggleFullscreen,
  onChangeProgress,
  onVideoSeekerMouseUp,
}) => {
  const [hideInitialOverlay, setHideInitialOverlay] = useState(false);
  const [fullscreenSupported] = useState(isFullscreenSupported);

  const hideInitialOverlayTimeout = () => {
    setHideInitialOverlay(true);
  };

  //Make sure we only have one instance of the hideInitialOverlayTimeout function at any time
  const debouncedHideOverlay = useMemo(() => debounce(hideInitialOverlayTimeout, 3000), []);

  useEffect(() => {
    if (isPlaying && !hideInitialOverlay) {
      debouncedHideOverlay();
    }
  }, [isPlaying, hideInitialOverlay, debouncedHideOverlay]);

  const initialOverlay = (
    <button
      onClick={togglePlaying}
      aria-label="Play video"
      className={
        'flex items-center justify-center w-64 h-64 mx-auto rounded-full transition-colors bg-light/[.65] [&:not(:focus-visible)]:hover:bg-light/[.80] ark-focus-visible cursor-pointer'
      }
    >
      <Play size={32} weight={'fill'} color={'#222222'} />
    </button>
  );

  const controls = (
    <div className="flex items-center w-full mx-8 mt-auto mb-8 h-fit sm:mb-32 sm:mx-32">
      <PlayButton onClick={togglePlaying} isPlaying={isPlaying} data-cy="play-btn" className="mr-8 sm:mr-16" />
      <MuteButton data-cy="mute-btn" onClick={toggleMute} isMuted={isMuted} />
      <VideoSeekBar
        progress={progress}
        onChangeProgress={onChangeProgress}
        onMouseUp={onVideoSeekerMouseUp}
        className={classNames({ 'mx-32': fullscreenSupported, 'mx-8': !fullscreenSupported })}
      />
      {fullscreenSupported && <FullscreenButton data-cy="fullscreen-btn" onClick={toggleFullscreen} />}
    </div>
  );

  const showControls = isPlaying && hideInitialOverlay;

  return (
    <div
      onClick={togglePlaying}
      className={classNames('flex items-center w-full h-full transition-opacity opacity-0', {
        'opacity-100': !showControls || (showControls && isHovering),
        'group-[&:has(*:focus-visible)]/video:opacity-100': !isHovering,
      })}
    >
      {isPlaying || hideInitialOverlay ? controls : initialOverlay}
    </div>
  );
};
