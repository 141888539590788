import { useEffect, useState } from 'react';

const FULLSCREEN_EVENTS = ['fullscreenchange', 'webkitfullscreenchange'];

// NB: Given the current implementation this hook should only be used once in the render tree.
// It does not validate which element dismiss from fullscreen.
const useFullscreenScrollReset = (): void => {
  const [scrollY, setScrollY] = useState(0);

  // Workaround to keep scroll position when exiting fullscreen on certain devices, i.e., iPad
  useEffect(() => {
    const listener = () => {
      const fullscreenElement = document.fullscreenElement || document['webkitFullscreenElement'];
      !!fullscreenElement ? setScrollY(window.scrollY) : window.scrollTo(0, scrollY);
    };

    FULLSCREEN_EVENTS.forEach((event) => {
      document.addEventListener(event, listener);
    });

    return () => {
      FULLSCREEN_EVENTS.forEach((event) => {
        document.removeEventListener(event, listener);
      });
    };
  }, [scrollY]);
};

export default useFullscreenScrollReset;
